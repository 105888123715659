export const siteTitle = 'Polaris Windows and Doors';

export const formBackendUrl = 'https://api.sellmorewindows.com/gfuljgluiarestn.php';

export const citiesForForm = [
  'Toronto',
  'Mississauga',
  'Barrie',
  'Hamilton',
  'Guelph',
  'Niagara',
];

export const sentryDsn = '';
